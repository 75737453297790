<script>
import Actions from "@/components/Forms/Actions";
import AgGridTableNumber from "@/components/Forms/FormAddClient/AgGridTableNumber";
export default {
  name: "ColumnDefsCreateRequest",
  components:{
    Actions,
    AgGridTableNumber
  },
  data(){
    return{
      column_defs_create_request: [

        {
          headerName: "",
          field: "name",
          cellRendererFramework: 'Actions',
          minWidth: 75,
          maxWidth: 75,
          cellRendererParams: {
            onEditClicked: params => {
              this.open_drawer_request({data:params.data}, true)
            },
            onDeleteClicked: params => {
              this.delete_method('create_request', 'organizations/customer/', params.data)
            },
            onWatchClicked: params => {
              this.open_drawer_request({data:params.data})
            },
          }
        },
        {
          headerName: "№",
          field: "full_name",
          minWidth: 75,
          maxWidth: 75,
          cellRendererFramework: 'AgGridTableNumber',
        },
        {
          headerName: this.$t('name'),
          field: "name",
          minWidth: 150,
          cellClass: ['a-link', 'cursor-pointer'],
          open_drawer:true,
        },
        {
          headerName: "#",
          field: "request_number_v2",
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "Дата",
          field: "created",
          // headerCheckboxSelection: true,
          // headerCheckboxSelectionFilteredOnly: true,
          // checkboxSelection: true,
          valueFormatter: function(params) {
            // Получаем дату из параметров
            var date = params.value;
            // Парсим дату в объект Date
            var formattedDate = new Date(date);
            // Получаем день, месяц и год
            var day = formattedDate.getDate().toString().padStart(2, '0');
            var month = (formattedDate.getMonth() + 1).toString().padStart(2, '0'); // Месяц начинается с 0
            var year = formattedDate.getFullYear().toString();
            // Формируем строку в формате "dd/MM/yyyy"
            var formattedDateString = `${day}/${month}/${year}`;
            // Возвращаем отформатированную дату
            return formattedDateString;
          },
          minWidth: 150,
        },
        // {
        //   headerName: "ФИО",
        //   field: "client.profile.full_name"
        // },
        {
          headerName: this.$t('client'),
          field: "client.profile.full_name",
        },
        {
          headerName: this.$t('request_type'),
          field: "request_type.name",
          minWidth: 150,
        },
        {
          headerName: this.$t('days_until_due'),
          field: "days_until_due",
          cellClassRules: {
            'red-background': params => Number(params.value) <= 0
          }
        },
        {
          headerName: this.$t('status'),
          field: "status_request.name",
          minWidth: 150,
        },
        {
          headerName: this.$t('organization'),
          field: "organization_request.name",
          minWidth: 150,
        },
        {
          headerName: this.$t('result'),
          field: "result_request.name",
          minWidth: 150,
        },
        {
          headerName: this.$t('level'),
          field: "level_request.name",
          minWidth: 150,
        },
      ],
    }
  }
}
</script>

<style>
.red-background{
  background-color: #ffeaea;
}
</style>
